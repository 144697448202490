import React, {useEffect} from 'react'
import { Link } from "react-router-dom"

const ProjectList = () => {

    // Component for listing all the existing projects (FOR TESTING ONLY!)

    useEffect(() => {
		// fetch('http://lara.jukkakokko.com/api/projects', {
		// 	method: 'GET',
		// 	headers: {
		// 		'Content-Type': 'application/json',
		// 		"Authorization": "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sYXJhLmp1a2tha29ra28uY29tXC9hcGlcL2F1dGhcL2xvZ2luIiwiaWF0IjoxNjMwOTk2NzU1LCJleHAiOjE2MzEwMDAzNTUsIm5iZiI6MTYzMDk5Njc1NSwianRpIjoiWE15ZUxRT3h1ZFI4VWlWUCIsInN1YiI6MSwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.ZyTy60qwDFm2Es8zYbOnlb1tmgBNK_O_tXHyICFN6aU"
		// 	},
		// })
		// .then(response => response.json())
		// .then(data => {
		// 	console.table(data)

		// 	// Print all projects (USE ONLY FOR TESTING)
		// 	document.querySelector("#projects").innerHTML = data.map( val => `
		// 		<li>
		// 			<a href="/feedbacks/${val.uid}">${val.name}</a>
		// 		</li>
		// 	`).join('')
			
		// })
		// .catch((error) => {
		// 	console.error('Error:', error);
		// });

    }, []);

    
    return (
        <ul id="projects">
            {/* Projects are fetched here */}
            <li>
                <Link to="/fi/feedbacks/bc21e302bac664a3a4cf5615a1eba805">Alluprojekti</Link>
            </li>
            <li>
                <Link to="/fi/feedbacks/31cf7016c781600aa6caa7c430efb937">RTA-tutkimus B2C 2021 syksy</Link>
            </li>
            <li>
                <Link to="/fi/feedbacks/44549d9d40f880824a7e889ae98f15a4">Super awesome project</Link>
            </li>
            <li>
                <Link to="/fi/feedbacks/ac182e9e497a09763cb6b8996e5ef0b5">Super ultra mega project with unnecessarily long title just for testing purposes</Link>
            </li>
            <li>
                <Link to="/fi/feedbacks/asdf">My new test project (expired)</Link>
            </li>
            <li>
                <Link to="/fi/feedbacks/123">(Non-existant project)</Link>
            </li>
            <li>
                <Link to="/fi/asdasdasd">(Non-existant page)</Link>
            </li>
        </ul>
    )
}

export default ProjectList