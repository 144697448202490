import React, {useEffect} from 'react'


const LanguageSelector = ({setLanguage, history, urlRest, langFromUrl}) => {

    // Add "active" class to the current language button
	const langButtonActivation = (lang) => {

		const langButtons = document.querySelectorAll("#language-selector > button")
		langButtons.forEach( btn => btn.classList.remove("active"))

		if (lang === "en") {
			document.querySelector("#lang-btn-en").classList.add("active")
		} else {
			document.querySelector("#lang-btn-fi").classList.add("active")
		}
	}
	

	// Language switcher. Set language state, push to router history & activate lang button
	const changeLanguage = (lang) => {
		setLanguage(lang)
		history.push(`/${lang + urlRest}`);

		langButtonActivation(lang)
	}


    useEffect(() => {
		langButtonActivation(langFromUrl)
    }, [ langFromUrl ]);



    return (
		<div id="language-selector">
			<button id="lang-btn-fi" onClick={() => changeLanguage("fi")} title="Valitse suomen kieli">FI</button>
			<div className="lang-separator">/</div>
			<button id="lang-btn-en" onClick={() => changeLanguage("en")} title="Select english">EN</button>
		</div>
    )
	
}

export default LanguageSelector