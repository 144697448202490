import React from 'react';


const FormRadiogrid = ({radioGridId, errors, errorMessage, title, register}) => {



    return (

        <div className={errors ? "form-group form-group-radio has-error" : "form-group form-group-radio"}>

            <h2>{title}</h2>

            <div className="radio-button-grid" id={"radio-button-grid__" + radioGridId} >
                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_0"} value="0" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">0</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_1"} value="1" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">1</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_2"} value="2" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">2</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_3"} value="3" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">3</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_4"} value="4" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">4</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_5"} value="5" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">5</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_6"} value="6" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">6</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_7"} value="7" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">7</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_8"} value="8" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">8</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_9"} value="9" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">9</span>
                </label>

                <label className="label-container">
                    <input type="radio" name={radioGridId} id={radioGridId + "_val_10"} value="10" {...register(radioGridId, { required: true })}/>
                    <span className="label-checkmark"></span>
                    <span className="label-name">10</span>
                </label>
            </div>


            <div className="error-message-area">
                {errors && <span>{errorMessage}</span>}
            </div>

        </div>
    )
}


export default FormRadiogrid;