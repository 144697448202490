
const EndMessage = ({translate}) => {
    return (
        <div id="endmessage">
            <div className="endmessage-content">
                <h2 dangerouslySetInnerHTML={translate("title-endmessage")}></h2>
                <a href="https://dqcomms.com/">{translate("close-endmessage", true)}<svg viewBox='0 0 27.45 10.22'><polygon points='0 4.29 17.93 4.29 17.93 0 27.45 5.01 17.93 10.22 17.93 5.87 0 5.87 0 4.29'/></svg></a>
            </div>
        </div>
    )
}

export default EndMessage;