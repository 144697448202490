import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import FeedbackForm from './components/Form';
import EndMessage from './components/EndMessage'


function Feedback({translate}) {

    // Get hash from routes
    let { hash } = useParams();
	

	// App main states
    const [ hasLoaded, setHasLoaded ] = useState(false);
    const [ hashMatch, setHashMatch ] = useState(false);
	const [ feedbackOpen, setFeedbackOpen ] = useState(false);
    const [ projectInfo, setProjectInfo ] = useState({
        name: '',
        expires_at: '',
        expires_in_time: 0
    })
    const [ formComplete, setFormComplete ] = useState(false)


    useEffect(() => {

		// Fetch the current project
		fetch(`https://dqcomms-feedback-api.atk7.dqcomms.com/api/projects/${hash}`, {
			method: 'GET',
			mode: 'cors'
		})
		.then(response => response.json())
		.then(data => {

            // Set loading state when fetch complete
            setHasLoaded(true)

            // If Swagger Project UID and current page hash matches, set "hashMatch" state to True
            if (data.uid === hash) {
                setHashMatch(true)

                // Set feedbackOpen state to true/false based on the project's "openforfeedback" value
                if (data.openforfeedback === true) {

                    setFeedbackOpen(true)
                    

                    const milliseconds_per_day = 1000 * 60 * 60 * 24

                    // a and b are javascript Date objects
                    function dateDiffInDays(a, b) {

                        // Discard the time and time-zone information.
                        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
                        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
                        return Math.floor((utc2 - utc1) / milliseconds_per_day)
                    }


                    // Get the two comparable dates
                    const a = new Date()
                    const b = new Date(data.expires_at)
                    

                    // Calculate the date difference in hours
                    let difference = (((b - a) / 1000) / 60) / 60



                    // Get the hours decimal number to formatted hours and minutes (e.g. 48.91 -> 48:55)
                    function convertNumToTime(number) {
                        // Check sign of given number
                        var sign = (number >= 0) ? 1 : -1;
                    
                        // Set positive value of number of sign negative
                        number = number * sign;
                    
                        // Separate the int from the decimal part
                        var hour = Math.floor(number);
                        var decpart = number - hour;
                    
                        var min = 1 / 60;
                        // Round to nearest minute
                        decpart = min * Math.round(decpart / min);
                    
                        var minute = Math.floor(decpart * 60) + '';
                    
                        // Add Sign in final result
                        sign = sign === 1 ? '' : '-';
                    
                        // Concate hours and minutes
                        var time = sign + hour + ':' + minute;
                    
                        return time;
                    }
                    //console.log(convertNumToTime(difference));




                    // Declare the expiration string based on the time left to expiration (along with translation + singlular/plural unit)

                    // If less than an hour, print time left in MINUTES
                    if (difference <= 1) {
                        difference = `${convertNumToTime(difference).split(":")[1]} 
                        ${convertNumToTime(difference).split(":")[1] === 1 ?
                            translate("meta-expiration-date-minute", true) : 
                            translate("meta-expiration-date-minutes", true)}
                        `
                    }
                    // If less than day, print time left in HOURS
                    else if (difference <= 24) {
                        difference = `${convertNumToTime(difference).split(":")[0]} 
                        ${Math.floor(difference) === 1 ? 
                            translate("meta-expiration-date-hour", true) : 
                            translate("meta-expiration-date-hours", true)
                        }`
                    }
                    // If more than 1 day, print time in DAYS
                    else {
                        difference = `
                            ${dateDiffInDays(a, b) - 1}
                            ${dateDiffInDays(a, b) - 1 === 1 ? 
                                translate("meta-expiration-date-day", true) : 
                                translate("meta-expiration-date-days", true)
                        }`
                    }

                    // console.log(translate("meta-expiration-date-day", true))


                    // Set the project data to state along 
                    setProjectInfo({ 
                        name: data.name,
                        expires_at: data.expires_at,
                        expires_in_time: difference
                    })


                } else {
                    setFeedbackOpen(false)
                    console.warn("Project not open for feedback: expired")
                }

            } else {
                setHashMatch(false)
                console.warn("Data UID and PageHash DO NOT MATCH")
            }

		})
		.catch((error) => {
            setHasLoaded(true);
            setHashMatch(false);
			console.error('Error: ', error);
		});

	}, [hash, setProjectInfo, translate])


    

   
    return(
        <>
        {/* Start fetching the project */}

        {hasLoaded === true ? (
            <>

            {/* Fetch ready. Check for mathing project UID and current page hash */}

            {hashMatch === true ? (
                <>

                {/* Project UID and page hash matched. Check for feedback expiration */}

                {feedbackOpen === true ? (
                    <>

                        {formComplete !== true ? (
                            <>
                            <header className="content-header">
                                <div className="inner">
                                    
                                    <h2>{projectInfo.name}</h2>

                                    
                                    <h1 dangerouslySetInnerHTML={translate("title-main")}></h1>

                                    <p className="project-meta">
                                        {translate("meta-expiration-date", true)} {projectInfo.expires_in_time}
                                        {/* <span className="expiration-date">
                                            ({new Date(projectInfo.expires_at).toLocaleDateString("fi-FI")}
                                            ,&nbsp;
                                            {new Date(projectInfo.expires_at).toLocaleTimeString("it-IT")})
                                        </span> */}
                                    </p>
                                    
                                    
                                </div>
                            </header>

                            <main className="main-content">

                                <p dangerouslySetInnerHTML={translate("description-main")}></p>

                                <p className="scoring-info">
                                    <span className="scoring-number" dangerouslySetInnerHTML={translate("rating-scale-0")}>
                                    </span>
                                    <span className="scoring-separator">/</span>
                                    <span className="scoring-number" dangerouslySetInnerHTML={translate("rating-scale-10")}>
                                    </span>
                                </p>


                                <FeedbackForm pageHash={hash} translate={translate} setFormComplete={setFormComplete} />
                                

                            </main>
                            </>

                        ) : (

                        <EndMessage translate={translate} />

                        )}

                    </>
                ) : (

                    // Project exists but has expired and is no longer open for feedback

                    <>
                        <header className="content-header">
                            <div className="inner">
                                <h1 dangerouslySetInnerHTML={translate("title-form-closed")}></h1>
                            </div>
                        </header>
                        <main className="main-content">
                            <p dangerouslySetInnerHTML={translate("description-form-closed")}></p>
                            <p dangerouslySetInnerHTML={translate("contact-form-closed")}></p>
                        </main>
                    </>
                )}
                </>

            ) : (

                // The fetched project could not be found. Project UID and current page hash did not match

                <>
                    <header className="content-header">
                        <div className="inner">
                            <h1 dangerouslySetInnerHTML={translate("title-project-not-found")}></h1>
                        </div>
                    </header>
                    <main className="main-content">
                        <p dangerouslySetInnerHTML={translate("description-project-not-found")}></p>
                    </main>
                </>
                )}
            </>
        ) : (

            // Fetching the project...

            <main className="main-content">
                <div className="loading">
                    <p dangerouslySetInnerHTML={translate("loading")}></p>
                </div>
            </main>
        )}

        </>

    );
}

export default Feedback;