import FormRadiogrid from './FormRadiogrid';
import { useForm } from 'react-hook-form';

const FeedbackForm = ({translate, pageHash, setFormComplete}) => {

    // React-hook-form definitions
    const { register, handleSubmit, formState: { errors }, reset} = useForm();


    // Textarea auto-resize on input
    const textareaResize = (e) => {
        e.target.style.height = "auto";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }

    // Handle form submit
    const onSubmit = formData => {

        // Upload the submitted feedback to the related project
        fetch(`https://dqcomms-feedback-api.atk7.dqcomms.com/api/projects/${pageHash}/feedbacks`, {
            method: 'POST',
			mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": formData.name,
                "quality": formData.quality,
                "cooperation": formData.cooperation,
                "comment": !formData.comment ? "(empty)" : formData.comment, // If comment field left empty on submit, apply "(empty)" string
                "marketing_permission": formData.marketing_permission === false ? 0 : 1
            })
        })
        .then(response => response.json())
		.then(data => {

            // console.table(data)

            // Set state to completed
            setFormComplete(true);

            // Reveal end message
            endMessage();

            // Finally reset the form
            reset();

            console.log("DATA UPLOAD SUCCESSFULL")

        })
        .catch((error) => {
			console.error('Error: ', error);
            alert("Whoops, something went wrong. Check the fields and try again.")
		});
    }

    // Reveal end message
    const endMessage = () => {
        document.body.style.overflow = "hidden"
        document.querySelector("#endmessage").classList.add("active")
    }

    return (
        <div className="form-wrapper">

            <form onSubmit={handleSubmit(onSubmit)} >

                <div className={errors.name ? "form-group form-group-text has-error" : "form-group form-group-text"}>

                    <h2 dangerouslySetInnerHTML={translate("user-name-title")}></h2>

                    <label className="label-container">
                        <span className="label-name">Name</span>
                        <textarea 
                            rows="1"
                            id="name"
                            className="form-field"
                            placeholder={translate('user-name-placeholder', true)}
                            {...register('name', { required: true })}
                            onInput={e => (textareaResize(e))}
                        ></textarea>
                    </label>

                    <div className="error-message-area">
                        {errors.name && <span>{translate("error-you-name", true)}</span>}
                    </div>

                </div>


                {/* ID and errors property must match the Swagger field properties (e.g 'quality', 'cooperation') */}
                <FormRadiogrid 
                    register={register}
                    errors={errors.quality}
                    radioGridId="quality"
                    title={translate("work-quality", true)}
                    errorMessage={translate("error-pick-rating", true)}
                />


                <FormRadiogrid 
                    register={register}
                    errors={errors.cooperation}
                    radioGridId="cooperation"
                    title={translate("cooperation-fluency", true)}
                    errorMessage={translate("error-pick-rating", true)}
                />

            


                <div className={errors.comment ? "form-group form-group-text has-error" : "form-group form-group-text"}>

                    <h2 dangerouslySetInnerHTML={translate("open-comment-title")}></h2>

                    <label className="label-container">
                        <span className="label-name">Open message</span>
                        <textarea 
                            rows="1"
                            id="comment"
                            className="form-field"
                            placeholder={translate('open-comment-placeholder', true)}
                            {...register('comment')}
                            onInput={e => (textareaResize(e))}
                        ></textarea>
                    </label>
                    
                    <div className="error-message-area">
                        {errors.comment && <span dangerouslySetInnerHTML={translate("error-pick-rating")}></span>}
                    </div>

                </div>
                


                <div className="form-group form-group-checkbox">
                    <label className="label-container">
                        <input 
                            type="checkbox"
                            id="marketing-permission"
                            name="marketing-permission"
                            {...register('marketing_permission')}
                        />
                        <span>{translate('comment-consent-title', true)}</span>
                    </label>
                </div>



                <div className="button-container">
                    <input 
                        type="submit"
                        value={translate("submit-form", true)}
                        className="submit-button"
                    />
                </div>


            </form>

        </div>
    )
}

export default FeedbackForm