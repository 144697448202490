import './App.scss'
import React, {useEffect, useState} from 'react'
import 'fix-date'
import { Switch, Route, useLocation, useHistory } from "react-router-dom"
import Feedback from './Feedback'
import ProjectList from './components/ProjectList'
import translation from './languages.json';
import logoDQ from './images/logo-dqc-black.svg'
import LanguageSelector from './components/LanguageSelector'


function App() {

	// Get language slug from url (fi / en)
	const langFromUrl = useLocation().pathname.split('/')[1];


	// Get the rest of the url after language slug
	const urlRest = useLocation().pathname.substring(useLocation().pathname.indexOf('/', 2))


	// Initialize language state (finnish by default)
	const [ language, setLanguage ] = useState('fi')


	// Initialize React router history
	const history = useHistory();


	// Get the translatable string from languages.json
	const translate = (input, insideElement) => {

		/* THE TWO USAGE EXAMPLES:
			<h1 dangerouslySetInnerHTML={translate("the-string")}></h1>
			<h1>{translate("close-endmessage", true)}</h1>
		*/

		if (insideElement) {
			// Use "insideElement" parameter with "true" value to translate inside an element instead of innerHTML
			return (JSON.parse(JSON.stringify(translation?.[input]?.[language] ?? '')))
		} else {
			// Use dangerouslySetInnerHTML to include html code
			return {__html: translation?.[input]?.[language] ?? ''};
		}

	}


	// Set the document title
	document.title = translate("page-title", true)

	
	useEffect(() => {
		// Set language state on effect
		setLanguage(langFromUrl)

		// Url language error checking
		if (langFromUrl === "") {
			history.push(`/fi${urlRest}`)
		}
		// else if (langFromUrl === "feedbacks") {
		// 	history.push(`/fi/feedbacks${urlRest}`)
		// }

    }, [ langFromUrl, history, urlRest ]);

	
	return (
    	<div className="App">

			<header className="main-header">
				<div className="inner">
					<a href="https://dqcomms.com/" target="_blank" rel="noreferrer noopener" title="Go to Drama Queen home page">
						<img src={logoDQ} alt="DQ" />
					</a>

					<LanguageSelector 
						langFromUrl={langFromUrl}
						setLanguage={setLanguage}
						history={history}
						urlRest={urlRest}
					/>

				</div>
			</header>

			
			<Switch>
				<Route exact path={"/" + language + "/examples"}>
					<header className="content-header">
						<div className="inner">
							<h1 dangerouslySetInnerHTML={translate("title-frontpage")}></h1>
						</div>
					</header>
					<main className="main-content">
						<p dangerouslySetInnerHTML={translate("description-frontpage")}></p>
						<br />
						<p className="text-green" dangerouslySetInnerHTML={translate("projectListTest")}></p>
						<ProjectList />
					</main>
				</Route>

				<Route path={"/" + language + "/feedbacks/:hash"} exact children={<Feedback translate={translate} />} />

				<Route path="*">
					<header className="content-header">
						<div className="inner">
							<h1 dangerouslySetInnerHTML={translate("title-404")}></h1>
						</div>
					</header>
				</Route>

			</Switch>
			
    	</div>
  	);
}

export default App;
